.upload-card {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed #cbd5e0;
    background-color: #edf2f7;
    width: 400px;
    height: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}


.file-input {
    position: absolute;
    text-align: right;
    opacity: 0;
    z-index: 2;
    top: 50px;
    cursor: pointer;
    height: 12pxpx;
    width: 160px;
    max-width: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.upload-btn {
    position: absolute;
    width: 150px;
    height: 42px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: none;



    color: #fff;
    background-color: #464646;
    font-size: 1.1rem;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 1em;
    transition: background-color 0.4s;
    box-shadow: 0px 6px 18px rgba(149, 157, 165, 0.5);

}

.upload-info {

    position: absolute;
    top: 70%;
    left: 52%;
    margin-top: 2px;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;


}


.main {
    font-weight: bold;
    margin-bottom: 0.4em;

}

.info {
    font-size: 0.8rem;
    text-align: center;
    font-weight: 600;

}