.player-card {
    background-color: #edf2f7;
    margin: 10% auto;
    margin-bottom: 50px;
    width: 426px;
    height: 240px;
    position: static;
}

video:focus {
    outline: none;
}

.player {
    background-color: #edf2f7;
    margin: 20px auto;
    margin-bottom: 50px;
    width: 426px;
    height: 240px;
    position: static;

}

.download-btn {
    text-transform: uppercase;
    background: #464646;
    width: 40%;
    border: 5;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 125px;

}